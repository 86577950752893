<div>

  <div style="position: relative;">

    <div fxLayout="column">
      <div *ngIf="isLoading && !isPreQualifiedLane" fxLayout="row" style="position: absolute; bottom: 50%; left: 50%; transform: translateX(-50%); "
        fxLayoutAlign="center center">
        <!-- <mat-icon aria-hidden="false" aria-label="loading" fontIcon="home"
                          matTooltip="loading" matTooltipClass="new-tooltip"
                          class="material-symbols-outlined company-icon">cached</mat-icon> -->
        Loading....
      </div>
      <div *ngIf="!isLoading && dataSource.data.length == 0 && !isPreQualifiedLane" fxLayout="row"
        style="position: absolute; bottom: 50%; left: 50%; transform: translateX(-50%);" fxLayoutAlign="center center">
        <!-- <mat-icon aria-hidden="false" aria-label="loading" fontIcon="home"
                        matTooltip="loading" matTooltipClass="new-tooltip"
                        class="material-symbols-outlined company-icon">cached</mat-icon> -->
        No data Found
      </div>
      <!-- exim table -->
      <div fxLayout="row" style="width: 100%; background: #fff; padding: 0 10px;" *ngIf="!isPreQualifiedLane && boardType == 'EXIM'">
        <div class="sb_table h-235" [ngClass]="{'h-305': isVisibleLanes}" style="width: 100%; min-height: unset;" infinite-scroll [infiniteScrollDistance]="5"
          [infiniteScrollThrottle]="80" [scrollWindow]="false" [fromRoot]="true" (scrolled)="onScrollDown()">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <!-- <ng-container matColumnDef="select" >
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <div style="text-align: center;">
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? selection.toggle(row) : null"
                              [checked]="selection.isSelected(row)">
                </mat-checkbox>
                </div>
              </td>
            </ng-container> -->
            <!-- tag Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef> Lead Details
                <i class="ri-arrow-down-s-line cursor-pointer filter-icon" (click)="leadSourceDialog()"></i>
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="table-row-content">
                  <a class="table-link" (click)="openCardDetails(element)" target="_self">{{element.displayId}}</a>
                  <p class="table-sub-details" *ngIf="element.referenceId">
                    <span class="table-sub-heading"><strong>Reference ID : </strong></span> <strong>{{element.referenceId}}</strong>
                  </p>
                  <p class="table-sub-details">
                    <span class="table-sub-heading">Created :</span> {{element.createdAt | date}}
                  </p>
                  <p class="table-sub-details">
                    <span class="table-sub-heading">Modified : </span> {{element.updatedAt | date}}
                  </p>
                </div>

              </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Company Details
                <!-- <i class="ri-filter-3-fill"></i> -->
              </th>
              <td mat-cell *matCellDef="let element" style=" width: 20% !important;">
                <div fxLayout="column">
                  <div fxLayout="row" fxLayoutAlign="start center" class="detailsAlign">
                    <div fxLayout="column" fxLayoutAlign="center start">
                      <span class="table-title">{{toLowerCase(element?.name) || "N/A"}}</span>
                    </div>
                  </div>
                  <ng-container *ngIf="getLeadContactDetails(element) as contact ">
                    <ng-container *ngIf="contact.phone != null && contact.phone != ''">
                      <div fxLayout="row" fxLayoutAlign="start center" class="detailsAlign">
                        <div fxLayout="column" fxLayoutAlign="center start">
                          <p class="table-sub-details">{{contact.phone}}</p>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="contact.email != null && contact.email != ''">
                      <div fxLayout="row" fxLayoutAlign="start center"
                        *ngIf="contact.phone != null && contact.phone != ''">
                        <div fxLayout="column" fxLayoutAlign="center start">
                          <p class="table-sub-details">{{contact.email}}</p>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="profile">
              <th mat-header-cell *matHeaderCellDef>Company Profile
                <!-- <i class="ri-filter-3-fill"></i> -->
              </th>
              <td mat-cell *matCellDef="let laneTaskIDDetail">
                <div fxLayout="column" fxLayoutAlign="start start" *ngIf="laneTaskIDDetail != null">
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <div fxLayout="row" fxLayoutAlign="center center" class="margin-right  company-buyer-div">
                      <!-- <mat-icon aria-hidden="false" aria-label="Company Name" fontIcon="home"
                              matTooltip="Buyers" matTooltipClass="new-tooltip"
                              class="material-symbols-outlined buyer-icon">group</mat-icon> -->

                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center">
                      <label class="icon-text-label">No. Of Buyers: </label>
                      <span class="icon-text-text">{{laneTaskIDDetail.noOfBuyers}}</span>
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <div fxLayout="row" fxLayoutAlign="center center" class="margin-right  company-limit-div">
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center">
                      <label class="icon-text-label">Limit Applied For: </label>
                      <span class="icon-text-text">{{laneTaskIDDetail.totalLimitApplied}}</span>
                    </div>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Lead Status

                  <i class="ri-arrow-down-s-line cursor-pointer filter-icon" *ngIf="selectedLane && selectedLane.currentValue['name'] != 'all-leads'" mat-button
                  [matMenuTriggerFor]="menu"></i>

                <mat-menu #menu="matMenu" (click)="menuClick($event)">
                  <!-- Lead ststus Filter Codes -->
                  <div class="LeadStatus leadStatusMatMenu">
                    <ul (click)="$event.stopPropagation();" class="checkbox-overflow-1 overflow-scroll-1"
                      [class.show]="show">
                      <li *ngFor="let status of boardLane | filter:seachLeadText">
                        <mat-checkbox (change)="getSelectedLeadstatusList($event, status)"
                          [disableRipple]="true">{{status.laneDTO.displayName}}</mat-checkbox>
                      </li>
                    </ul>
                    <button mat-raised-button class="savebtn" (click)="applyLeadFilters()">Apply Filter</button>
                  </div>
                </mat-menu>
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="getLeadStatus(element) as status">
                  <p class="table-sub-details"> {{status.lead_status}}</p>
                </ng-container>
              </td>
            </ng-container>


            <ng-container matColumnDef="source">
              <th mat-header-cell *matHeaderCellDef> Lead Source
                <!-- <i class="ri-arrow-down-s-line cursor-pointer filter-icon" *ngIf="selectedLane && selectedLane.currentValue['name'] != 'all-leads'" mat-button
                  [matMenuTriggerFor]="menu">
                </i> -->
                <mat-menu #menu="matMenu" (click)="menuClick($event)" class="leadsSourceMenu">
                  <!-- Lead ststus Filter Codes -->
                  <div class="LeadStatus leadStatusMatMenu">
                    <ul (click)="$event.stopPropagation();" class="checkbox-overflow-1 overflow-scroll-1"
                      [class.show]="show">
                      <li *ngFor="let status of boardLane | filter:seachLeadText">
                        <mat-checkbox (change)="getSelectedLeadstatusList($event, status)"
                          [disableRipple]="true">{{status.laneDTO.displayName}}</mat-checkbox>
                      </li>
                    </ul>
                    <button mat-raised-button class="savebtn" (click)="applyLeadFilters()">Apply Filter</button>
                  </div>
                </mat-menu>
              </th>
              <td mat-cell *matCellDef="let leadsSource">
                <ng-container>
                  <p class="table-sub-details">{{leadsSource.leadSource ? (leadsSource.leadSource).replace('_',' ') : '--'}}</p>
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="analyst">
              <th mat-header-cell *matHeaderCellDef> Assignee Details
                <!-- <i class="ri-filter-fill filter-assignee" (click)="boardFiltersOpen();"> </i> -->
                <i class="ri-filter-3-fill cursor-pointer filter-icon" (click)="boardFiltersOpen('otherLeads');"></i>
              </th>

              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.assignee != null && element.assignee.length > 0">
                  <div fxLayout="row" fxLayoutAlign="start center" class="comments-icon-div" style="width: 100%;">
                    <p class="table-sub-details"> {{element?.assignee[0]?.user?.name}}</p>
                  </div>
                  <p *ngIf="element?.assignedOn" class="table-sub-details"> <span class="table-sub-heading">Assigned On
                      :
                    </span>{{element?.assignedOn | date}}</p>
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell *matCellDef="let element">
                <div class="actions-list">
                  <img src="../../../../../assets/icons/edit-black.svg" matTooltip="Edit Lead"
                    (click)="openCardDetails(element)" matTooltipClass="new-tooltip" />
                  <span class="changeAssignee" matTooltip="Change Assignee" matTooltipClass="new-tooltip">
                    <i class="ri-user-shared-2-fill" (click)="changeAssignee(element)"></i>
                  </span>


                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">

            </tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
              [ngClass]="{ 'active-row': i === selectedTrIndex }">

            </tr>
          </table>

        </div>

      </div>
      <!-- exim table end -->

      <!-- dometic table -->
      <div fxLayout="row" style="width: 100%; background: #fff; padding: 0 10px;" *ngIf="!isPreQualifiedLane && boardType != 'EXIM'">
        <div class="sb_table h-235" [ngClass]="{'h-305': isVisibleLanes}" style="width: 100%; min-height: unset;" infinite-scroll [infiniteScrollDistance]="5"
          [infiniteScrollThrottle]="80" [scrollWindow]="false" [fromRoot]="true" (scrolled)="onScrollDown()">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <!-- <ng-container matColumnDef="select" >
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <div style="text-align: center;">
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? selection.toggle(row) : null"
                              [checked]="selection.isSelected(row)">
                </mat-checkbox>
                </div>
              </td>
            </ng-container> -->
            <!-- tag Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef> Lead Details
                <i class="ri-arrow-down-s-line cursor-pointer filter-icon" (click)="leadSourceDialog()"></i>
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="table-row-content">
                  <a class="table-link" (click)="openCardDetails(element)" target="_self">{{element.displayId}}</a>
                  <p class="table-sub-details" *ngIf="element.referenceId">
                    <span class="table-sub-heading"><strong>Reference ID : </strong></span> <strong>{{element.referenceId}}</strong>
                  </p>
                  <p class="table-sub-details">
                    <span class="table-sub-heading">Created :</span> {{element.createdAt | date}}
                  </p>
                  <p class="table-sub-details">
                    <span class="table-sub-heading">Modified : </span> {{element.updatedAt | date}}
                  </p>
                </div>

              </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Company Details
                <!-- <i class="ri-filter-3-fill"></i> -->
              </th>
              <td mat-cell *matCellDef="let element" style=" width: 20% !important;">
                <div fxLayout="column">
                  <div fxLayout="row" fxLayoutAlign="start center" class="detailsAlign">
                    <div fxLayout="column" fxLayoutAlign="center start">
                      <span class="table-title">{{toLowerCase(element?.name) || "N/A"}}</span>
                    </div>
                  </div>
                  <ng-container *ngIf="getLeadContactDetails(element) as contact ">
                    <ng-container *ngIf="contact.phone != null && contact.phone != ''">
                      <div fxLayout="row" fxLayoutAlign="start center" class="detailsAlign">
                        <div fxLayout="column" fxLayoutAlign="center start">
                          <p class="table-sub-details">{{contact.phone}}</p>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="contact.email != null && contact.email != ''">
                      <div fxLayout="row" fxLayoutAlign="start center"
                        *ngIf="contact.phone != null && contact.phone != ''">
                        <div fxLayout="column" fxLayoutAlign="center start">
                          <p class="table-sub-details">{{contact.email}}</p>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="profile">
              <th mat-header-cell *matHeaderCellDef>Company Profile
                <!-- <i class="ri-filter-3-fill"></i> -->
              </th>
              <td mat-cell *matCellDef="let laneTaskIDDetail">
                <div fxLayout="column" fxLayoutAlign="start start" *ngIf="laneTaskIDDetail != null">
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <div fxLayout="row" fxLayoutAlign="center center" class="margin-right  company-buyer-div">
                      <!-- <mat-icon aria-hidden="false" aria-label="Company Name" fontIcon="home"
                              matTooltip="Buyers" matTooltipClass="new-tooltip"
                              class="material-symbols-outlined buyer-icon">group</mat-icon> -->

                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="showSuppliers(laneTaskIDDetail)">
                      <label class="icon-text-label">No. Of {{businessName}}: </label>
                      <span class="icon-text-text">{{getPrimaryInfo(laneTaskIDDetail,'length')}}</span>
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <div fxLayout="row" fxLayoutAlign="center center" class="margin-right  company-limit-div">
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center">
                      <label class="icon-text-label">Limit Applied For: </label>
                      <span class="icon-text-text">{{getPrimaryInfo(laneTaskIDDetail,'sum')}}</span>
                    </div>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Lead Status

                  <i class="ri-arrow-down-s-line cursor-pointer filter-icon" *ngIf="selectedLane && selectedLane.currentValue['name'] != 'all-leads'" mat-button
                  [matMenuTriggerFor]="menu"></i>

                <mat-menu #menu="matMenu" (click)="menuClick($event)">
                  <!-- Lead ststus Filter Codes -->
                  <div class="LeadStatus leadStatusMatMenu">
                    <ul (click)="$event.stopPropagation();" class="checkbox-overflow-1 overflow-scroll-1"
                      [class.show]="show">
                      <li *ngFor="let status of boardLane | filter:seachLeadText">
                        <mat-checkbox (change)="getSelectedLeadstatusList($event, status)"
                          [disableRipple]="true">{{status.laneDTO.displayName}}</mat-checkbox>
                      </li>
                    </ul>
                    <button mat-raised-button class="savebtn" (click)="applyLeadFilters()">Apply Filter</button>
                  </div>
                </mat-menu>
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="getLeadStatus(element) as status">
                  <p class="table-sub-details"> {{status.lead_status}}</p>
                </ng-container>
              </td>
            </ng-container>


            <ng-container matColumnDef="source">
              <th mat-header-cell *matHeaderCellDef> Lead Source
                <!-- <i class="ri-arrow-down-s-line cursor-pointer filter-icon" *ngIf="selectedLane && selectedLane.currentValue['name'] != 'all-leads'" mat-button
                  [matMenuTriggerFor]="menu">
                </i> -->
                <mat-menu #menu="matMenu" (click)="menuClick($event)" class="leadsSourceMenu">
                  <!-- Lead ststus Filter Codes -->
                  <div class="LeadStatus leadStatusMatMenu">
                    <ul (click)="$event.stopPropagation();" class="checkbox-overflow-1 overflow-scroll-1"
                      [class.show]="show">
                      <li *ngFor="let status of boardLane | filter:seachLeadText">
                        <mat-checkbox (change)="getSelectedLeadstatusList($event, status)"
                          [disableRipple]="true">{{status.laneDTO.displayName}}</mat-checkbox>
                      </li>
                    </ul>
                    <button mat-raised-button class="savebtn" (click)="applyLeadFilters()">Apply Filter</button>
                  </div>
                </mat-menu>
              </th>
              <td mat-cell *matCellDef="let leadsSource">
                <ng-container>
                  <p class="table-sub-details">{{leadsSource.leadSource ? (leadsSource.leadSource).replace('_',' ') : '--'}}</p>
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="analyst">
              <th mat-header-cell *matHeaderCellDef> Assignee Details
                <!-- <i class="ri-filter-fill filter-assignee" (click)="boardFiltersOpen();"> </i> -->
                <i class="ri-filter-3-fill cursor-pointer filter-icon" (click)="boardFiltersOpen('otherLeads');"></i>
              </th>

              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.assignee != null && element.assignee.length > 0">
                  <div fxLayout="row" fxLayoutAlign="start center" class="comments-icon-div" style="width: 100%;">
                    <p class="table-sub-details"> {{element?.assignee[0]?.user?.name}}</p>
                  </div>
                  <p *ngIf="element?.assignedOn" class="table-sub-details"> <span class="table-sub-heading">Assigned On
                      :
                    </span>{{element?.assignedOn | date}}</p>
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell *matCellDef="let element">
                <div class="actions-list">
                  <img src="../../../../../assets/icons/edit-black.svg" matTooltip="Edit Lead"
                    (click)="openCardDetails(element)" matTooltipClass="new-tooltip" />
                  <span class="changeAssignee" matTooltip="Change Assignee" matTooltipClass="new-tooltip">
                    <i class="ri-user-shared-2-fill" (click)="changeAssignee(element)"></i>
                  </span>


                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">

            </tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
              [ngClass]="{ 'active-row': i === selectedTrIndex }">

            </tr>
          </table>

        </div>

      </div>
      <!-- domestic table ends -->
      <!--Prequalified Leads Filters-->
      <div class="pre_filters" *ngIf="isPreQualifiedLane && isVisibleLanes">
        <div class="filter-inputs">
          <div class="search-box">
            <div class="formfield border-right search-main-box">
              <label>Search</label>
              <mat-form-field appearance="outline" class="mog3_matinput">
                <span class="pr-5"><i class="ri-search-line"></i></span>
                  <input matInput type="text" placeholder="Search Lead ID/Name" aria-label="Number" [formControl]="searchControl"
                    (keyup.enter)="getPreTable(searchControl.value, 'leadSearch')" />
              </mat-form-field>
            </div>
            <div class="searchedListing" *ngIf="leadList && leadList.length > 0">
              <div class="sk-fading-circle" *ngIf="autoSuggestloading && autoSuggestloading == false">
                <div class="sk-circle1 sk-circle"></div>
                <div class="sk-circle2 sk-circle"></div>
                <div class="sk-circle3 sk-circle"></div>
                <div class="sk-circle4 sk-circle"></div>
                <div class="sk-circle5 sk-circle"></div>
                <div class="sk-circle6 sk-circle"></div>
                <div class="sk-circle7 sk-circle"></div>
                <div class="sk-circle8 sk-circle"></div>
                <div class="sk-circle9 sk-circle"></div>
                <div class="sk-circle10 sk-circle"></div>
                <div class="sk-circle11 sk-circle"></div>
                <div class="sk-circle12 sk-circle"></div>
              </div>
            </div>
            <div class="search-list-box">
              <ul *ngFor="let option of leadList; let i = index">
                <!-- <li class="cursor-pointer" (click)="openCardDetails(option.id)">{{option.searchkey}}</li> -->
                <li class="cursor-pointer" (click)="openCardDetails(option.id)">
                  <p><strong>Lead ID :</strong> {{option.searchkey}}</p>
                  <p><strong>Name:</strong> {{option.supplierName}}</p>
                </li>
              </ul>
            </div>
            </div>
            <div class="formfield border-right search-main-box">
              <label>Assignee</label>
              <mat-form-field appearance="outline" class="mog3_matinput">
                <input type="text" placeholder="Assigned To" aria-label="Number" matInput [formControl]="myControl" [matAutocomplete]="auto">
              <div class="autocomplete-wrapper">
                <mat-autocomplete class="assignee-mat" #auto="matAutocomplete"
                  (optionSelected)="selectedUserList($event,'assignee')" panelClass="custom-autocomplete-panel">
                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    {{option}}
                  </mat-option>
                </mat-autocomplete>
              </div>
            </mat-form-field>
          </div>
          <div class="border-right p-5">
            <mat-form-field appearance="outline" class="mog3-matselect">
              <label>Supplier Email</label>
              <mat-select placeholder="Exists/Not Exists" [(ngModel)]="isSupplierEmailsExist">
                <mat-option value="true">Exists</mat-option>
                <mat-option value="false">Not Exists</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="search-box">
            <div class="formfield border-right search-main-box">
              <label>Buyer</label>
              <mat-form-field appearance="outline" class="mog3_matinput">
                <span class="pr-5"><i class="ri-search-line"></i></span>
                <input type="text" placeholder="Search Buyer Name" aria-label="Number" matInput
                  (input)="searchBuyers($event.target.value)" [(ngModel)]="selectedBuyerName"
                  (keyup.enter)="getPreTable(selectedBuyerName,'buyerSearch')">
              </mat-form-field>
            </div>
            <div class="searchedListing" *ngIf="buyerList && buyerList.length > 0">
              <div class="sk-fading-circle" *ngIf="buyerDataLoading && buyerDataLoading == false">
                <div class="sk-circle1 sk-circle"></div>
                <div class="sk-circle2 sk-circle"></div>
                <div class="sk-circle3 sk-circle"></div>
                <div class="sk-circle4 sk-circle"></div>
                <div class="sk-circle5 sk-circle"></div>
                <div class="sk-circle6 sk-circle"></div>
                <div class="sk-circle7 sk-circle"></div>
                <div class="sk-circle8 sk-circle"></div>
                <div class="sk-circle9 sk-circle"></div>
                <div class="sk-circle10 sk-circle"></div>
                <div class="sk-circle11 sk-circle"></div>
                <div class="sk-circle12 sk-circle"></div>
              </div>
            </div>
            <div class="search-list-box-2">
              <ul *ngFor="let option of buyerList; let i = index">
                <!-- <li class="cursor-pointer" (click)="selectedBuyer(option)">{{option.buyerName}}</li> -->
                <li class="cursor-pointer" (click)="selectedBuyer(option)">
                  <p><strong>Lead Id :</strong> {{option.searchkey}}</p>
                  <p><strong>Name:</strong> {{option.buyerName}}</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="border-right p-5">
            <mat-form-field appearance="outline" class="mog3-matselect">
              <label>Phone No.</label>
              <mat-select placeholder="Exists/Not Exists" [(ngModel)]="isSupplierPhoneNumberExist">
                <mat-option value="true">Exists</mat-option>
                <mat-option value="false">Not Exists</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="border-right p-5">
            <mat-form-field appearance="outline" class="mog3-matselect">
              <label>State</label>
              <mat-select placeholder="Select State" [(ngModel)]="exporterState">
                <mat-option *ngFor="let state of stateList" [value]="state">{{state}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="border-right p-5">
            <mat-form-field appearance="outline" class="mog3-matselect">
              <label>Campaign</label>
              <mat-select placeholder="Select Campaign" [(ngModel)]="selectedCampaignName">
                <mat-option *ngFor="let campaign of campaignList" [value]="campaign">{{campaign}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="border-right p-5">
            <mat-form-field appearance="outline" class="mog3-matselect">
              <label>Source</label>
              <mat-select placeholder="Select Source" [(ngModel)]="selectedSource">
                <mat-option *ngFor="let source of sourceList" [value]="source">{{source}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="isPreQualifiedLane && this.boardType != 'EXIM'" class="border-right p-5">
            <mat-form-field appearance="outline" class="mog3-matselect">
              <label>Business Turnover</label>
              <mat-select placeholder="Select Turnover" [(ngModel)]="selectedTurnover">
                <mat-option *ngFor="let turnOver of bussinessTypeList" [value]="turnOver">{{turnOver}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        <div *ngIf="isPreQualifiedLane && this.boardType != 'EXIM'" class="border-right p-5 select-calendar">
          <mat-form-field class="mog3-matselect">
            <!-- <mat-label>From Date</mat-label> -->
            <input placeholder="Form Date" matInput [matDatepicker]="picker1" [(ngModel)]="selectedFromDate" placeholder="Select From Date" (click)="picker1.open()" readonly>
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div *ngIf="isPreQualifiedLane && this.boardType != 'EXIM'" class="border-right p-5 select-calendar">
          <mat-form-field class="mog3-matselect">
            <!-- <mat-label>To Date</mat-label> -->
            <input placeholder="To Date" matInput [matDatepicker]="picker2" [(ngModel)]="selectedToDate" placeholder="Select To Date" (click)="picker2.open()" readonly>
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
          
                    <!-- <div class="border-right p-5">
            <mat-form-field appearance="outline" class="mog3-matselect">
              <label>Business Unit</label>
              <mat-select placeholder="Select Campaign" [(ngModel)]="businessType">
                <mat-option value="EXPORT">Export</mat-option>
                <mat-option value="DOMESTIC">Domestic</mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
          <div class="btn-div">
            <button class="apply-btn" (click)="applyPreFilter()">Apply All</button>
            <button class="clear-all" (click)="clearAllFilter()">Clear All</button>
          </div>

        </div>
        <div class="filter-upload">
          <button class="template" (click)="openLeadPopUp()">
            <!-- <span class="icon-dlwd"><i class="ri-download-2-fill"></i></span> -->
            Leads To Contact
          </button>
          <button class="template" (click)="downloadLead()">
            <span class="icon-dlwd"><i class="ri-download-2-fill"></i></span>
            Template
          </button>
          <div class="upload">
            <span class="icon-upld"><i class="ri-upload-2-line"></i></span>
            <button class="upload__btn">Upload File</button>
            <input class="upload__input" type="file" name="myfile" (change)="onFileChange($event)"
              (click)="fileInput.value = null" value="" #fileInput />
          </div>
        </div>
      </div>
      <div class="status-pagi-div">
        <div class="lead-status" *ngIf="isPreQualifiedLane">
          <button (click)="onStatusChange('FRESH_LEAD')" [ngClass]="getHighlightedFilter(isClickedFresh)">Fresh Leads:
            {{this.statsCount?.freshLead}}</button>
          <button (click)="onStatusChange('IN_PROGRESS')" [ngClass]="getHighlightedFilter(isClickedProgress)">In
            Progress:
            {{this.statsCount?.inProgress}}</button>
          <button (click)="onStatusChange('MOVE_TO_LOS')" [ngClass]="getHighlightedFilter(isClickedMove)">Moved to LOS:
            {{this.statsCount?.moveToLos}}
            <span (click)="laneChangeOnCurrentStatus()"><mat-icon class="current-status">open_in_new</mat-icon></span>
          </button>
            <!-- <button (click)="onStatusChange('MOVE_TO_LOS')" [ngClass]="getHighlightedFilter(isClickedMove)">Demo:
              {{this.statsCount?.moveToLos}}</button> -->
          <button (click)="onStatusChange('DROPPED')" [ngClass]="getHighlightedFilter(isClickedDropped)">Dropped:
            {{this.statsCount?.dropped}}</button>
          <div class="select-current" *ngIf="isClickedProgress">
            <div class="formfield">
              <mat-form-field appearance="outline" class="mog3-matselect">
                <mat-select multiple placeholder="Current Stage" (selectionChange)="onCurrentStageChange($event.value)">
                  <mat-option *ngFor="let status of subStatusList" [value]="status.name">{{status.name}}
                    ({{status.value}})</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="select-current">
            <div class="formfield">
              <mat-form-field appearance="outline" class="mog3-matselect">
                <mat-select placeholder="Bulk Action" (selectionChange)="changeBulkAction($event.value)"
                  [(ngModel)]="preQualifiedBulk">
                  <mat-option value="bulkAssignee">Change Assignee</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div>
            <button class="btnSetting newBtn" mat-button (click)="hideLanes()"
              [matTooltip]="isVisibleLanes ? 'Collapse': 'Expand'">
              <mat-icon *ngIf="isVisibleLanes">expand_less</mat-icon>
              <mat-icon *ngIf="!isVisibleLanes">keyboard_arrow_down</mat-icon>
            </button>
          </div>
          <!-- <button (click)="onStatusChange('DROPPED')" [ngClass]="{'active':isClickedDropped}" (click)="isClickedDropped = (isClickedDropped ? false :true )">Today's Target</button> -->
        </div>

        <div class="pagination-wrapper" *ngIf="isPreQualifiedLane">
          <mat-paginator [hidden]="!dataSource.data.length" [length]="totalRecords" [pageIndex]="pageNumber"
            class="custom-pagintor" [pageSize]="size" [pageSizeOptions]="[10,25,50,75,100]" showFirstLastButtons
            (page)="getPaginatorTableData($event)">
          </mat-paginator>
        </div>
      </div>
      <div fxLayout="row" style="width: 100%;" *ngIf="isPreQualifiedLane">
        <div class="pre_table h-215" [ngClass]="{'h-380': isVisibleLanes}" style="width: 100%; min-height: unset;">
          <table mat-table [dataSource]="dataSource1" class="mat-elevation-z8">
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterPreToggle() : null"
                  [checked]="selection.hasValue() && isAllPreSelected()"
                  [indeterminate]="selection.hasValue() && !isAllPreSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox>
              </th>
              <td [ngClass]="getScore(element.leadScore)" mat-cell *matCellDef="let element" class="alignCheckBox">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(element) : null"
                  [checked]="selection.isSelected(element)" [aria-label]="checkboxLabel(element)">
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="searchKey">
              <th mat-header-cell *matHeaderCellDef> Lead ID
                <span class="cursor-pointer" (click)="leadScoreOrderBy()">
                  <i *ngIf="isLeadOrderBy" class="ri-arrow-up-s-fill"></i>
                  <i *ngIf="!isLeadOrderBy" class="ri-arrow-down-s-fill"></i>
                </span>
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="table-row-content">
                  <!-- <a class="table-link" target="_self">{{element.searchKey}}</a> -->
                  <h4 class="cursor-pointer" (click)="openCardDetails(element.id)">{{element.searchKey}} <span
                      class="red-label" *ngIf="element.isDuplicate">(Duplicate)</span></h4>
                  <p>Exporter : <b>{{element.exporterName ? (element.exporterName) : '--'}}</b></p>
                  <p>Lead Score : <b><span class="bg-c">{{element.leadScore}}</span></b></p>
                  <p>Business Type : <b><span class="bg-c">{{element.businessType | titlecase }}</span></b></p>
                  <span class="dates">
                    <p>Created Date : <b>{{element.createdAt ? (element?.createdAt | date) : ''}}</b></p>
                  </span>
                  <span class="dates">
                    <p>Modified Date : <b>{{element.updatedAt ? (element?.updatedAt | date) : ''}}</b></p>
                  </span>
                </div>

              </td>
            </ng-container>
            <ng-container matColumnDef="leadSummary">
              <th mat-header-cell *matHeaderCellDef> Lead Summary
                <!-- <i mat-button class="ri-filter-3-fill"></i> -->
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="table-row-content">
                  <!-- <a class="table-link" target="_self">{{element.buyerRegion}}</a> -->
                  <p class="lead-sum"><span><i
                        [ngClass]="element.buyerQualified ? 'ri-check-double-line' : 'ri-close-line'"></i></span>Buyer
                    Qualified</p>
                  <p><span><i
                        [ngClass]="element.annualTurnoverValue != null ? 'ri-check-double-line' : 'ri-close-line'"></i></span>Supplier
                    Turn Over</p>
                  <p><span><i
                        [ngClass]="element.contactDetailAdded ? 'ri-check-double-line' : 'ri-close-line'"></i></span>Supplier
                    Contact Details</p>
                  <p class="lead-sum"><span><i
                        [ngClass]="element.supplierContacted ? 'ri-check-double-line' : 'ri-close-line'"></i></span>Supplier
                    Contacted</p>
                  <p><span><i
                        [ngClass]="element.supplierInterested ? 'ri-check-double-line' : 'ri-close-line'"></i></span>Supplier
                    Interested</p>
                </div>

              </td>
            </ng-container>
            <ng-container matColumnDef="nextStep">
              <th mat-header-cell *matHeaderCellDef> Next Step
                <!-- <i mat-button class="ri-filter-3-fill"></i> -->
              </th>
              <td mat-cell *matCellDef="let element">
                <div *ngIf="element.nextSteps?.length > 0; else noTasks">
                  <div class="table-row-content tasks-pending" *ngFor="let item of element.nextSteps">
                    <i class="ri-time-line"></i><span>{{item}}</span>
                  </div>
                </div>
                <ng-template #noTasks>
                  <div class="tasks-pending">
                    <i class="ri-check-double-line tasks-done"></i>
                    <span>All Steps Done</span>
                  </div>
                </ng-template>
              </td>
            </ng-container>
            <ng-container matColumnDef="leadStatus">
              <th mat-header-cell *matHeaderCellDef> <span [matMenuTriggerFor]="statusFilterWrapRef">Lead Sub Status <i
                    mat-button class="ri-filter-3-fill"></i></span>
                <mat-menu #statusFilterWrapRef="matMenu" class="statusFilter">
                  <div class="filter-wrapper-w">
                    <ng-container *ngFor="let item of leadStatus; let index = index">
                      <div class="f-w" (click)="$event.stopPropagation()">
                        <mat-checkbox [checked]="item.checked" (change)="changeStatus($event,index)"></mat-checkbox>
                        {{ item.name }}
                      </div>
                    </ng-container>
                  </div>
                  <div class="btn-wrap">
                    <button class="reset" (click)="resetFilterStatus()">
                      Reset
                    </button>
                    <button class="apply" (click)="applyStatusFilter()">
                      Apply
                    </button>
                  </div>
                </mat-menu>
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="table-row-content">
                  <!-- <a class="table-link" target="_self">{{element.buyerRegion}}</a> -->
                  <p><span class="status-dot"></span>{{element.status?.replaceAll('_',' ') | titlecase}}</p>
                </div>
                <div class="table-row-content" *ngIf="element.subStatus && element.subStatus.trim() != '' && element.subStatus.trim().length > 0 && (element.status == 'DROPPED' || element.status == 'IN_PROGRESS')">
                  <p [matTooltip]="element.subStatus">
                    <span class="status-dot truncate"></span>
                    <b *ngIf="element.status == 'DROPPED'">Reason : </b>
                    {{element.subStatus}}
                  </p>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="exporterProfile">
              <th mat-header-cell *matHeaderCellDef> Exporter Profile
                <!-- <i mat-button class="ri-filter-3-fill"></i> -->
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="table-row-content">
                  <p *ngIf="boardType == 'EXIM'">Annual Turnover - <b>INR {{element.annualTurnoverValue && isNumber(element.annualTurnoverValue)  ? (element.annualTurnoverValue | number :'1.0-2') : ''}} Cr.</b></p>
                  <p *ngIf="boardType != 'EXIM'">Annual Turnover - <b>{{element.annualTurnoverValue ? element.annualTurnoverValue : ""}}</b></p>
                   <p *ngIf="boardType == 'DOMESTIC' && element.exporterCity">City - <b>{{element.exporterCity}}</b></p>
                  <p *ngIf="boardType == 'DOMESTIC' && element.exporterState">State - <b>{{element.exporterState }}</b></p>
                  <p>{{element.spoc1PhoneNumber}}</p>
                  <div class="buyer-div">
                    <p>Buyers</p>
                    <span>
                      <button mat-button [matMenuTriggerFor]="buyerMenu"><i class="ri-information-fill"></i></button>
                      <mat-menu class="info-inside-sale" #buyerMenu="matMenu">
                        <p class="buyer-p" mat-menu-item><strong>Buyer Name</strong></p>
                        <span class="buyer-span" mat-menu-item
                        *ngFor="let name of element.buyerDataList">{{name.buyerName}}</span>
                      </mat-menu>
                    </span>
                  </div>
                  <div class="buyer-div" *ngIf="element.campaignName">
                    <span>Campain Name : <strong> {{element.campaignName}}</strong></span> 
                  </div>
                  <div class="buyer-div cmpSrc" *ngIf="element.leadSource">
                    <span>Source : <strong> {{element.leadSource}}</strong></span>
                  </div>
                  <p *ngIf="boardType == 'EXIM' && element.countryCode">Country Code - <b>{{element.countryCode}}</b></p>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="assigneeDetails">
              <th mat-header-cell *matHeaderCellDef> Assignee Details
                <i mat-button class="ri-filter-3-fill" (click)="boardFiltersOpen('pre-qualified')"></i>
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="table-row-content">
                  <!-- <a class="table-link" target="_self">{{element.buyerRegion}}</a> -->
                  <p><b>{{element.assigneeDTO?.userName ? element.assigneeDTO?.userName : 'No Assignee'}}</b></p>
                  <span class="dates">
                    <p>Assigned on : {{element.assignedOn ? (element.assignedOn | date) : '--'}}</p>
                  </span>
                </div>

              </td>
            </ng-container>
            <ng-container matColumnDef="btnAction">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell *matCellDef="let element">
                <div class="actions-list cursor-pointer">
                  <i class="ri-pencil-line" matTooltip="Edit Lead" (click)="openCardDetails(element.id)"
                    matTooltipClass="new-tooltip"></i>
                  <!-- <img src="../../../../../assets/icons/edit-black.svg" matTooltip="Edit Lead"
                    (click)="openCardDetails(element)" matTooltipClass="new-tooltip" /> -->
                  <span matTooltip="Change Assignee" matTooltipClass="new-tooltip">
                    <i class="ri-user-shared-2-line" (click)="changePreQualifiedAssigne(element)"></i>
                  </span>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">

            </tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
              [ngClass]="{ 'active-row': i === selectedTrIndex }">

            </tr>
          </table>
        </div>
      </div>

    </div>
  </div>
</div>
