import { Component, Input, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef, HostBinding, SimpleChanges, HostListener } from '@angular/core'
import { KanbanService } from '../../kanban.service'
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop'
import { MatAutocompleteSelectedEvent, MatDialog, MatMenu } from '@angular/material'
import { LaneMoveComponent } from '../lanes-list/lane-move/lane-move.component'

import { ToastrService } from 'ngx-toastr'
import { SelectionModel } from '@angular/cdk/collections'
import { ViewChild } from '@angular/core'
import { MatPaginator, MatSelect, MatTableDataSource } from '@angular/material'
import { ViewLaneComponent } from './view-lane/view-lane.component'
import { LaneTaskUsersTabComponent } from './card-details/lane-task-users-tab/lane-task-users-tab.component'
import { HistoryTabComponent } from './card-details/history-tab/history-tab.component'
import { KBoardFiltersComponent } from '../k-board-filters/k-board-filters.component'
import { PresaleService } from 'src/app/component/pre-sales/presale.service'
import { saveAs } from 'file-saver'
import { PagesService } from 'src/app/pages/pages.service'
import { UploadMappingComponent } from 'src/app/settings/upload-mapping/upload-mapping/upload-mapping.component'
import { Observable, Subject } from 'rxjs'
import { FormControl } from '@angular/forms'
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators'
import { SidService } from 'src/app/component/sid/services/sid.service'
import { LosFiltersComponent } from '../los-filters/los-filters.component'
import { LeadOnQualifierComponent } from '../lanes-list/lead-on-qualifier/lead-on-qualifier.component'

@Component({
	selector: 'app-board-lane',
	templateUrl: './board-lane-listing.component.html',
	styleUrls: ['./board-lane.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
export class BoardLaneComponent implements OnChanges {
	@Input() board_id: string
	@Input() filteredObj: {}
	@Input() loading
	@Input() laneTile
	@Input() boardLaneResponseObj: []
	@Input() sobFilter
	@Input() boardType: any
	selectedTrIndex: number = -1
	leadListing: any = []
	preQualifiedData: any = []
	isLoading: boolean = false
	leadConfigResult = []
	selection = new SelectionModel<any>(true, [])
	dataSource = new MatTableDataSource(this.leadListing)
	dataSource1 = new MatTableDataSource(this.preQualifiedData)
	@ViewChild(MatPaginator) paginator: MatPaginator
	@ViewChild(BoardLaneComponent) boardLaneComp: BoardLaneComponent
	boardLane = []
	boardId: any
	laneTaskList = []
	boardLaneResponse: any
	projectLists = []
	queryLanes: any[]
	selectedLane: any
	isEditAccessLane: false
	pageSize = 20
	pageFrom = 0
	lanePageSize = 5
	lanePageFrom = 0
	pageIndex = -1
	totalRecords
	searchText = ''
	leadFile: any
	displayedColumns: string[] = []
	userList: string[] = []
	statsCount: any
	leadStatus: any[] = [
		{ name: 'Fresh Lead', value: 'FRESH_LEAD', checked: false },
		{ name: 'In Progress', value: 'IN_PROGRESS', checked: false },
		{ name: 'Move to LOS', value: 'MOVE_TO_LOS', checked: false },
		{ name: 'Dropped', value: 'DROPPED', checked: false },
	]
	statusList: any = []
	assigneeId: string
	filteredOptions: Observable<string[]>
	myControl = new FormControl('')
	searchLeadId: any
	searchLeadName: string
	fetchUser = []
	campaignList = []
	sourceList = [];
	leadList: any = []
	buyerList: any = []
	selectedBuyerName: string
	selectedBuyerId: string
	isClickedFresh: boolean = false
	isClickedProgress: boolean = false
	isClickedMove: boolean = false
	isClickedDropped: boolean = false
	exporterState: string
	// displayedColumns1 : ["select","id","name","profile","status","analyst","action"]
	@HostBinding('class') class = 'block-100'
	@ViewChild('matRefStatus') matRefStatus: MatSelect
	selectedLeadstatusList = new Set()
	sendFilteredReqObj: { searchText: string }
	assigneeFilter: any
	pageNumber: number = 0
	size: number = 10
	isSupplierEmailsExist: string
	isSupplierPhoneNumberExist: string
	autoSuggestloading: boolean = false
	buyerDataLoading: boolean = false
	losStatus: boolean = false
	stateList = []
	checkedCurrentStageList = []
	isSearchBox: boolean = false
	searchValue: string
	searchType: string
	businessName: string = 'Buyer'
	subStatusList: any = [
		{ name: 'Contacted - DNP', key: 'contactedDNP', value: 0 },
		{ name: 'Contacted - Followup', key: 'contactedFollowup', value: 0 },
		{ name: 'Interested - Buyer details awaited', key: 'interestedBuyerDetailsAwait', value: 0 },
		{ name: 'Interested - Meeting Alligned', key: 'interestedMeetingAlligned', value: 0 },
		{ name: 'Contact in Future', key: 'contactInFuture', value: 0 },
		{ name: 'Not Interested - Customer Reject', key: 'notInterestedCustomerReject', value: 0 },
		{ name: 'Not Interested - Out of Scope', key: 'notInterestedOutOfScope', value: 0 },
		{ name: 'Transferred to RSM', key: 'transferredToRSM', value: 0 },
		{ name: 'Onboarded', key: 'onboarded', value: 0 },
	]
	statsList: any = [
		'contactedDNP',
		'contactedFollowup',
		'interestedBuyerDetailsAwait',
		'interestedMeetingAlligned',
		'contactInFuture',
		'notInterestedCustomerReject',
		'notInterestedOutOfScope',
		'transferredToRSM',
		'onboarded',
	]
	bussinessTypeList: Array<string> = [
		'Rs. 0 to 40 lakhs',
		'Rs. 40 lakhs to 1.5 Cr.',
		'Rs. 1.5 Cr. to 5 Cr.',
		'Rs. 5 Cr. to 25 Cr.',
		'Rs. 25 Cr. to 100 Cr.',
		'Rs. 100 Cr. to 500 Cr.',
		'Rs. 500 Cr. and above',
	]
	selectedSource: String
	selectedCampaignName: string
	selectedTurnover: String
	businessType: string
	preQualifiedBulk
	leadOrderBy: string
	isLeadOrderBy: boolean = false
	isSobFilterApplied: boolean = false
	selectedLeadSource: any
	isVisibleLanes: boolean = true
	buyerLength: any
	buyerLimit: number
	primaryContact: any
	isbusinessLoan: boolean = false;
	selectedFromDate: any; 
	selectedToDate: any;
	searchControl: FormControl = new FormControl();
	private destroy$ = new Subject();

	constructor(
		public _kService: KanbanService,
		public preSaleService: PresaleService,
		public toasterService: ToastrService,
		public cRef: ChangeDetectorRef,
		public dialog: MatDialog,
		public pagesService: PagesService,
		public sidService: SidService,
	) {
		this.getSearchValueChange();
	}

	ngOnInit() {
		this.businessType = this.boardType == 'EXIM' ? 'Exim' : 'Domestic'
		this._kService.isVisibleLanes.subscribe((newValue) => {
			this.isVisibleLanes = newValue
		})
		this._kService.inSideSaleBusinessLoan.subscribe((newValue) => {
			this.isbusinessLoan = newValue
			this.applyPreFilter()
		})
	}
	ngAfterViewInit() {
		this.boardId = this.board_id
		this.isLoading = this.loading
		this.boardLaneResponse = this.boardLaneResponseObj
		if (this.isLoading) {
			this.laneTaskList = []
			this.leadListing = []
			this.dataSource.data = []
			this.totalRecords = 0
		}
		// this.fetchBoardLane();
		this.cRef.detectChanges()
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes && Object.keys(changes).length == 5) {
			return // Ignoring if everything changes hack
		}
		if (changes && changes.board_id && changes.board_id.currentValue) {
			this.boardId = changes.board_id.currentValue
		}
		if (this.boardId) {
			if (changes && changes.filteredObj && changes.filteredObj.currentValue) {
				let text = changes.filteredObj.currentValue.searchText
				if (text != null && text.trim() != '') {
					this.searchText = text
				} else {
					this.searchText = ''
				}
			} else {
				this.searchText = ''
			}
			if (changes && changes.boardLaneResponseObj && changes.boardLaneResponseObj.currentValue) {
				this.boardLaneResponse = changes.boardLaneResponseObj.currentValue
			}

			this.isLoading = true
			this.laneTaskList = []
			this.leadListing = []
			this.dataSource.data = []
			this.totalRecords = 0
			this.selectedLane = changes.laneTile
			if (!this.isPreQualifiedLane) {
				if (changes && changes.sobFilter && changes.sobFilter.currentValue) {
					this.isSobFilterApplied = changes.sobFilter.currentValue
				} else {
					this.isSobFilterApplied = false
				}
				this.displayedColumns = ['id', 'name', 'profile', 'status', 'source', 'analyst', 'action']
				this.fetchBoardLane()
			} else {
				this.displayedColumns = ['select', 'searchKey', 'leadSummary', 'nextStep', 'leadStatus', 'exporterProfile', 'assigneeDetails', 'btnAction']
				this.getLeadFileConfig()
				this.getPreQualifiedFilterObj()
				this.getUserListBasedOnRoles()
				this.getStates()
				this.getCampaignList()
				this.openLeadPopUp();
				this.getLeadSourceList();
			}
		}
		this.pagesService.moveToLostCurrent.subscribe((res) => {
			if (res) {
				this.losStatus = true
			}
		})
	}
	openLeadPopUp() {
		const businessType = this.boardType == 'EXIM' ? 'Exim' : 'Domestic'
		const dialogRef = this.dialog.open(LeadOnQualifierComponent, {
			disableClose: true,
			width: '800px',
			height: 'auto',
			panelClass: 'lead-on-qualifier',
			data: { businessType: businessType },
		})
		dialogRef.afterClosed().subscribe((result) => {})
	}
	get isPreQualifiedLane() {
		if (this.selectedLane != null && this.selectedLane.currentValue['name'] == 'pre-qualified') {
			return true
		}
	}
	getStates() {
		this.sidService.getStates().subscribe((res) => {
			this.stateList = res['states_india']
		})
	}

	applyPreFilter() {
		//set paginator page index to 0 while applying any filter including business loan filter
		if (this.paginator && this.paginator['pageIndex']) {
			this.paginator['pageIndex'] = 0
		}
		this.getPreQualifiedFilterObj()
	}
	clarAllPreQualifiedFilter() {
		this.isSupplierEmailsExist = null
		this.isSupplierPhoneNumberExist = null
		this.assigneeId = null
		this.myControl.setValue('')
		this.selectedBuyerId = null
		this.exporterState = null
		this.assigneeFilter = null
		this.selectedCampaignName = null
		this.selectedSource = null;
		this.selectedTurnover = null
		// this.businessType = null
		this.statusList = []
		this.leadStatus = [
			{ name: 'Fresh Lead', value: 'FRESH_LEAD', checked: false },
			{ name: 'In Progress', value: 'IN_PROGRESS', checked: false },
			{ name: 'Move to LOS', value: 'MOVE_TO_LOS', checked: false },
			{ name: 'Dropped', value: 'DROPPED', checked: false },
		]
		this.removeStatusClass()
	}
	hideLanes() {
		this.isVisibleLanes = !this.isVisibleLanes
		this._kService.setValue(this.isVisibleLanes)
	}

	getPreQualifiedFilterObj() {
		let obj = {
			from: this.paginator && this.paginator['pageIndex'] ? this.paginator['pageIndex'] : 0,
			size: this.size,
			where: {
				isDeleted: {
					type: 'search',
					value: false,
				},
			},

			['indexName']: 'credlix_presales',
			sort: {
				field: this.leadOrderBy ? 'leadScore' : 'updatedAt',
				order: this.leadOrderBy ? this.leadOrderBy : 'desc',
			},
		}
		if (this.isSupplierPhoneNumberExist != null) {
			obj['where']['isSupplierPhoneNumberExist'] = {
				type: 'boolean',
				value: this.isSupplierPhoneNumberExist == 'true' ? true : false,
			}
		}
		if (this.isSupplierEmailsExist != null) {
			obj['where']['isSupplierEmailsExist'] = {
				type: 'boolean',
				value: this.isSupplierEmailsExist == 'true' ? true : false,
			}
		}
		if (this.assigneeId != null) {
			obj['where']['assignedTo.id'] = {
				type: 'search',
				value: this.assigneeId,
			}
		}
		if (this.selectedBuyerId != null) {
			obj['where']['buyerData.buyerName'] = {
				type: 'search',
				value: this.selectedBuyerId,
			}
		}
		if (this.exporterState != null) {
			obj['where']['exporterState'] = {
				type: 'search',
				value: this.exporterState,
			}
		}
		if (this.assigneeFilter && this.assigneeFilter.assignList) {
			obj['where']['assignedTo.id'] = {
				type: 'search_in',
				value: this.assigneeFilter.assignList,
			}
		}
		if (this.assigneeFilter && this.assigneeFilter.fromDate) {
			obj['startDate'] = new Date(this.assigneeFilter.fromDate).getTime()
		}
		if (this.assigneeFilter && this.assigneeFilter.endDate) {
			obj['endDate'] = new Date(this.assigneeFilter.endDate).getTime()
		}
		if (this.statusList.length > 0) {
			obj['where']['status'] = {
				type: 'search_in',
				value: this.statusList,
			}
		}
		if (this.checkedCurrentStageList && this.checkedCurrentStageList.length > 0) {
			obj['where']['subStatus'] = {
				type: 'search_in',
				value: this.checkedCurrentStageList,
			}
		}
		if (this.selectedCampaignName) {
			obj['where']['campaignName'] = {
				type: 'search',
				value: this.selectedCampaignName,
			}
		}
		if(this.selectedSource){
			obj['where']['leadSource'] = {
				type: 'search',
				value: this.selectedSource,
			}
		}
		if (this.selectedTurnover) {
			obj['where']['annualTurnoverValue'] = {
				type: 'search_term',
				value: this.selectedTurnover,
			}
		}
		if (this.boardType != 'EXIM') {
			obj['where']['productType'] = {
				type: 'search_in',
				value: this.isbusinessLoan ? ['Business Loan'] : [],
			}
		}
		if (this.businessType) {
			obj['where']['businessType'] = {
				type: 'search_in',
				value: this.boardType == 'EXIM' ? [this.businessType, 'Export'] : [this.businessType],
			}
		}
		if (this.selectedFromDate) {
			obj['where']['createdAt'] = {
				type: 'num_range',
				"start_value": this.selectedFromDate.valueOf(),
				"end_value": this.selectedToDate.valueOf(),
			}
		}
		this.getPreQualifiedTable(obj)
		this.getCount(obj)
	}

	handleBlur(){
		setTimeout(() => {
			this.leadList = [];
		}, 500);
	}
	getPreQualifiedTable(obj) {
		this.preSaleService.getFileDetailsById(obj).subscribe((res) => {
			this.isLoading = false
			this.preQualifiedData = res['result']
			this.dataSource1 = new MatTableDataSource(this.preQualifiedData)
			this.dataSource1.data = res['result']
			this.totalRecords = res['total']
		})
	}
	fetchBoardLane(param?) {
		if (this.boardLaneResponse == null) {
			return
		}
		if (this._kService.isTabChanageFromTile) {
			this.pageFrom = 0
		}
		let response = this.boardLaneResponse
		//   this._kService.getLaneWithUserAccess(this.boardId, '', this.lanePageSize, this.lanePageFrom).subscribe(response => {
		let previousBoardLaneLength: number = this.boardLane.length
		if (response && response['result'] && response['result']['levelAccess']) {
			this.boardLane = this.boardLane.concat(response['result']['levelAccess'])
		}
		this.boardLane = Object.values(this.boardLane.reduce((acc, cur) => Object.assign(acc, { [cur.laneDTO.id]: cur }), {}))
		this.boardLane = this.boardLane.sort((a, b) => a.laneDTO.position - b.laneDTO.position).map((exemple, index, array) => exemple)
		this.boardLane['totalRecords'] = response['total']
		let tmpQueryLanes = []
		for (let i = 0; i < 1; i++) {
			if (response['result'] && response['result']['levelAccess'] && response['result']['levelAccess'][i]['accessType'].includes('EDIT')) {
				response['result']['levelAccess'][i].laneDTO['isEditAccess'] = true
			}
		}
		if (response['result'] && response['result']['levelAccess']) {
			for (let i = 0; i < response['result']['levelAccess'].length; i++) {
				tmpQueryLanes.push(response['result']['levelAccess'][i]['laneDTO'].id)
			}
		}
		this.queryLanes = tmpQueryLanes
		let obj = {
			where: {
				'lane.$id': {
					type: 'objectid_in',
					value: tmpQueryLanes,
				},
				isDeleted: {
					type: 'search',
					value: false,
				},
			},
			sort: { field: 'updatedAt', order: 'desc' },
			from: this.pageFrom,
			size: this.pageSize,
		}
		if (this.selectedLane != null && this.selectedLane.currentValue['name'] != 'all-leads') {
			obj['where']['lane.$id'] = {
				type: 'objectid_in',
				value: [this.selectedLane.currentValue.id],
			}
		}
		if (this.filteredObj['assignList'] != null && this.filteredObj['assignList'].length > 0) {
			obj['where']['assignee.user.id'] = {
				type: 'in',
				value: this.filteredObj['assignList'],
			}
		}
		if (this.filteredObj['tagList'] != null && this.filteredObj['tagList'].length > 0) {
			obj['where']['tags.id'] = {
				type: 'in',
				value: this.filteredObj['tagList'],
			}
		}
		if (this.filteredObj['laneList'] != null && this.filteredObj['laneList'].length > 0) {
			obj['where']['lane.id'] = {
				type: 'in',
				value: this.filteredObj['laneList'],
			}
		}
		if (this.searchText && this.searchText.trim() != '') {
			let name = {
				type: 'multi_match_query',
				value: [
					{
						field: 'displayName',
						value: this.searchText,
						type: 'like',
					},
					{
						field: 'displaySequenceName',
						value: this.searchText,
						type: 'like',
					},
					{
						field: 'referenceId',
						value: this.searchText,
						type: 'like',
					},
				],
			}

			obj['where']['multi_match_query'] = name
			obj['size'] = 20
		}
		if (this.isSobFilterApplied) {
			// if SOB Filter Applied on All-Leads Page
			obj['where']['leadSource'] = {
				type: 'search',
				value: 'SOB',
			}
		}
		if (this.losStatus) {
			obj['where']['leadSource'] = {
				type: 'search',
				value: 'WEBSITE',
			}
		}
		if (this.selectedLeadSource) {
			obj['where']['leadSource'] = {
				type: 'search',
				value: this.selectedLeadSource.selectedLeadSource,
			}
		}
		if (obj['where']['lane.$id']['value'].length > 0) {
			this._kService.getLaneTask(obj).subscribe((res) => {
				this.pageIndex++
				this.isLoading = false
				this.dataSource = new MatTableDataSource(this.leadListing)
				this.laneTaskList = res['result']
				this.leadListing = res['result']
				this.dataSource.data = res['result']
				this.totalRecords = res['total']
			})
		}
	}
	currentLaneMovedID: string
	existed(event: CdkDragDrop<any>, id) {
		this.currentLaneMovedID = id
	}

	// isAllSelected() {
	//   if (this.selection.selected) {

	//   }
	//   else {

	//   }
	//   const numSelected = this.selection.selected.length;
	//   const numRows = this.dataSource.data.length;
	//   // .filter((row: any) => {
	//   //   if(this.authService.isBuyer()){
	//   //     return row.status == 'BUYER_APPROVAL_PENDING';
	//   //   }
	//   //   else if(this.authService.isSIDUSER()){
	//   //     return row.status == 'ADMIN_APPROVAL_PENDING' ||
	//   //     row.status == 'BUYER_APPROVA displayedColumns = [];
	// }
	isAllSelected() {
		const numSelected = this.selection.selected.length
		const numRows = this.dataSource.data.length
		return numSelected === numRows
	}
	boardFiltersOpen(val) {
		let obj = {}
		if (val != 'pre-qualified') {
			obj = {
				boardId: this.boardId,
				reqObj: this.sendFilteredReqObj,
			}
		} else {
			obj = {
				isPreQualified: true,
				reqObj: this.sendFilteredReqObj,
			}
		}
		const dialogRef = this.dialog.open(KBoardFiltersComponent, {
			width: '373px',
			height: '100%',
			data: obj,
			panelClass: 'eximSetting',
		})
		dialogRef.afterClosed().subscribe((result) => {
			if (val != 'pre-qualified') {
				this.sendFilteredReqObj = result
				if (this.sendFilteredReqObj && this.boardId) {
					this._kService.isTabChanageFromTile = true
					this.updatebaordList(this.sendFilteredReqObj, this.boardId)
				} else {
					this.fetchBoardLane()
				}
			} else {
				this.assigneeFilter = result
				this.getPreQualifiedFilterObj()
			}
		})
	}

	getSelectedLeadstatusList(event: any, status: any) {
		const statusId = status.laneDTO.id
		if (event.checked) {
			this.selectedLeadstatusList.add(statusId)
		} else {
			this.selectedLeadstatusList.delete(statusId)
		}
	}

	applyLeadFilters() {
		// const selectedLeadstatusArray = [...this.selectedLeadstatusList];
		let selecteArray = Array.from(this.selectedLeadstatusList)
		this.getLeadFilteredData(selecteArray)
	}

	getLeadFilteredData(reqObj: any) {
		if (this._kService.isTabChanageFromTile) {
			this.pageFrom = 0
		}
		let obj = {
			where: {
				isDeleted: {
					type: 'search',
					value: false,
				},
				'lane.$id': {
					type: 'objectid_in',
					value: reqObj ? reqObj : [],
				},
			},
			sort: {
				field: 'createdAt',
				order: 'desc',
			},
			from: 0,
			size: 20,
		}
		this._kService.getLaneTask(obj).subscribe((data: any) => {
			this.dataSource = new MatTableDataSource(this.leadListing)
			this.dataSource.data = data['result']
		})
	}
	updatebaordList(reqObj, boardId) {
		this.isLoading = true
		if (this._kService.isTabChanageFromTile) {
			this.pageFrom = 0
		}
		let obj = {
			where: {
				isDeleted: {
					type: 'search',
					value: false,
				},
				'lane.$id': {
					type: 'objectid_in',
					value: this.selectedLane.currentValue['name'] === 'all-leads' ? this.queryLanes : [this.selectedLane.currentValue.id],
				},
			},
			sort: {
				field: 'createdAt',
				order: 'desc',
			},
			from: 0,
			size: 20,
			startDate: reqObj.fromDate,
			endDate: reqObj.endDate,
		}
		if (reqObj.assignList && reqObj.assignList.length != 0) {
			obj['where']['assignee.user.id'] = {
				type: 'in',
				value: reqObj.assignList,
			}
		}
		if (reqObj.selectedLeadSource) {
			obj['where']['leadSource'] = {
				type: 'search',
				value: reqObj.selectedLeadSource,
			}
		}
		this._kService.getLaneTask(obj).subscribe((data: any) => {
			this.isLoading = false
			this.dataSource = new MatTableDataSource(this.leadListing)
			this.dataSource.data = data['result']
		})
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row))
	}

	logSelection() {
		this.selection.selected.forEach((s) => console.log(s.name))
	}

	toLowerCase = (text) => {
		if (text) return text.toLowerCase()
		else return ''
	}
	getLeadStatus = (lead) => {
		let status = {
			application_status: '',
			lead_status: '',
		}
		if (lead.lane.name == 'new-leads') {
			status['application_status'] = 'Application Created'
		} else if (lead.lane.position > 1 && lead.lane.position < 5) {
			status['application_status'] = 'Application Processing'
		} else if (lead.lane.position == 5) {
			status['application_status'] = 'Awaiting Termsheet'
		} else if (lead.lane.position > 5) {
			status['application_status'] = 'Termsheet Issued'
		}
		status['lead_status'] = lead.lane.displayName
		return status
	}
	getLeadContactDetails = (lead) => {
		var contact = {
			email: null,
			phone: null,
		}
		if (this.boardType != 'DOMESTIC') {
			if (lead['data'] != null && lead['data'].length > 0) {
				lead['data'].find((element) => {
					if (element['field'] == 'contact_details') {
						if (element['operationsData'] != null && element['operationsData']['CONTACT_DETAILS'] != null) {
							let contactDetails = element['operationsData']['CONTACT_DETAILS']
							if (contactDetails != null && contactDetails.length > 0) {
								for (let k = 0; k < contactDetails.length; k++) {
									let ele = contactDetails[k]
									if (contact['email'] != null && contact['phone'] != null) {
										break
									}
									if (ele['phone'] != null && ele['isPrimary'] && ele['phone'].trim() != '' && contact['phone'] == null) {
										contact['phone'] = ele['phone'].trim()
									}
									if (ele['email'] != null && ele['isPrimary'] && ele['email'].trim() != '' && contact['email'] == null) {
										contact['email'] = ele['email'].trim()
									}
								}
							}
							if (contact['email'] == null && contact['phone'] == null) {
								if (element && element.operationsData && element.operationsData.CONTACT_DETAILS && element.operationsData.CONTACT_DETAILS.length > 0) {
									contact['phone'] = element.operationsData.CONTACT_DETAILS[0]['phone']
									contact['email'] = element.operationsData.CONTACT_DETAILS[0]['email']
									return contact
								}
							}
						}
					}
				})
			}
		} else {
			if (lead.domesticData.NEW_LEADS && lead.domesticData.NEW_LEADS.contact.length > 0) {
				lead.domesticData.NEW_LEADS.contact.forEach((element) => {
					if (element.isPrimary) {
						contact['phone'] = element.phone
						contact['email'] = element.email
					}
				})
			}
		}
		return contact
	}
	drop(event: CdkDragDrop<string[]>, id) {
		let currentLanePosition: number
		let previousLanePosition: number
		let currentLaneName: string
		let previousLaneName: string
		for (let i = 0; i < this.boardLane.length; i++) {
			if (this.boardLane[i].laneDTO.id == event.container.id) {
				currentLanePosition = this.boardLane[i].laneDTO.position
				currentLaneName = this.boardLane[i].laneDTO.name
			}
			if (this.boardLane[i].laneDTO.id == event.previousContainer.id) {
				previousLanePosition = this.boardLane[i].laneDTO.position
				previousLaneName = this.boardLane[i].laneDTO.name
			}
		}
		let obj = {
			id: this.currentLaneMovedID,
			laneId: event.previousContainer.data[0]['laneId'],
			toLaneID: event.container.id,
			currentLaneName: currentLaneName,
			previousLaneName: previousLaneName,
		}
		if (event.previousContainer === event.container) {
			//moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {
			if (previousLanePosition > currentLanePosition) {
				this.callForTaskMovement(event, obj)
			} else if (currentLanePosition > previousLanePosition) {
				this.assignLaneTaskOneToAnother(obj, event)
			}
		}
	}

	callForTaskMovement(event, obj) {
		obj['coldLeadReasons'] = this.setReasonToMove
		this._kService.assignLaneTaskMovement(obj).subscribe((res) => {
			if (res['status'] == 200) {
				transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex)
				this.fetchBoardLane()
			}
		})
	}
	// onPreQualifiedScrollDown(){
	//   if (this.pageSize < this.totalRecords) {
	//     this.pageFrom = this.pageFrom + 1;
	//     if(this._kService.isTabChanageFromTile){
	//       this.pageFrom = 1;
	//       this._kService.isTabChanageFromTile = false;
	//     }
	//     let obj = {
	//       "from": this.pageSize*this.pageFrom,
	//       "size": this.pageSize,
	//         "where": {
	//           "isDeleted": {
	//             "type": "search",
	//             "value": false
	//           },
	//         },

	//         ['indexName']: "credlix_presales",
	//         "sort": {
	//           "field": "updatedAt",
	//           "order": "desc"
	//         }
	//     }
	//     this.getPreQualifiedTable(obj);
	//   }
	// }

	onScrollDown() {
		if (this.sendFilteredReqObj && this.sendFilteredReqObj['assignList']) {
			this.onScrollFilter()
		} else {
			console.log('onScrollDown')
			if (this.pageSize < this.totalRecords) {
				this.pageFrom = this.pageFrom + 1
				if (this._kService.isTabChanageFromTile) {
					this.pageFrom = 1
					this._kService.isTabChanageFromTile = false
				}
				let obj = {
					where: {
						'lane.$id': {
							type: 'objectid_in',
							value: this.queryLanes,
						},
						isDeleted: {
							type: 'search',
							value: false,
						},
					},
					sort: { field: 'updatedAt', order: 'desc' },

					from: this.pageSize * this.pageFrom,
					size: this.pageSize,
				}
				if (this.selectedLane != null && this.selectedLane.currentValue['name'] != 'all-leads') {
					obj['where']['lane.$id'] = {
						type: 'objectid_in',
						value: [this.selectedLane.currentValue.id],
					}
				}
				if (this.filteredObj['assignList'] != null && this.filteredObj['assignList'].length > 0) {
					obj['where']['assignee.user.id'] = {
						type: 'in',
						value: this.filteredObj['assignList'],
					}
				}
				if (this.filteredObj['tagList'] != null && this.filteredObj['tagList'].length > 0) {
					obj['where']['tags.id'] = {
						type: 'in',
						value: this.filteredObj['tagList'],
					}
				}
				if (this.filteredObj['laneList'] != null && this.filteredObj['laneList'].length > 0) {
					obj['where']['lane.id'] = {
						type: 'in',
						value: this.filteredObj['laneList'],
					}
				}
				if (this.searchText && this.searchText.trim() != null) {
					let name = {
						type: 'multi_match_query',
						value: [
							{
								field: 'displayName',
								value: this.searchText,
								type: 'like',
							},
							{
								field: 'displaySequenceName',
								value: this.searchText,
								type: 'like',
							},
							{
								field: 'referenceId',
								value: this.searchText,
								type: 'like',
							},
						],
					}

					obj['where']['multi_match_query'] = name
					obj['size'] = 20
				}
				if (this.selectedLeadSource) {
					obj['where']['leadSource'] = {
						type: 'search',
						value: this.selectedLeadSource.selectedLeadSource,
					}
				}
				if (this.losStatus) {
					obj['where']['leadSource'] = {
						type: 'search',
						value: 'WEBSITE',
					}
				}
				this.isLoading = true
				this.getLaneTask(obj, this.laneTaskList)
			}
		}
	}

	onScrollFilter() {
		console.log('onScrollFilter')
		let obj = {}
		if (this.pageSize < this.totalRecords) {
			this.pageFrom = this.pageFrom + 1
			if (this._kService.isTabChanageFromTile) {
				this.pageFrom = 1
				this._kService.isTabChanageFromTile = false
			}
			if (this.sendFilteredReqObj && this.sendFilteredReqObj['assignList'] != null && this.sendFilteredReqObj['assignList'].length > 0) {
				obj = {
					where: {
						// "boardType": {
						//   "type": "search",
						//   "value": this.boardId === "625d9d0a38f7d64b1f177304" ? "EXIM" : "DOMESTIC"
						// },
						isDeleted: {
							type: 'search',
							value: false,
						},
						'lane.$id': {
							type: 'objectid_in',
							value: this.queryLanes,
						},
					},
					sort: { field: 'createdAt', order: 'desc' },

					from: this.pageSize * this.pageFrom,
					size: this.pageSize,
				}
				if (this.selectedLane.currentValue.name != 'all-leads') {
					obj['lane.$id'] = {
						type: 'objectid_in',
						value: this.queryLanes,
					}
				}
				// else {
				//   obj['lane.$id'] = {
				//     "type": "objectid_in",
				//     "value": this.queryLanes
				//   }
				// }
			}

			if (this.selectedLane != null && this.selectedLane.currentValue['name'] != 'all-leads') {
				obj['where']['lane.$id'] = {
					type: 'objectid_in',
					value: [this.selectedLane.currentValue.id],
				}
			}
			if (typeof this.sendFilteredReqObj != 'undefined' && this.sendFilteredReqObj['assignList'] != null && this.sendFilteredReqObj['assignList'].length > 0) {
				obj['where']['assignee.user.id'] = {
					type: 'in',
					value: this.sendFilteredReqObj['assignList'],
				}
			}
			if (typeof this.sendFilteredReqObj != 'undefined' && this.sendFilteredReqObj['tagList'] != null && this.sendFilteredReqObj['tagList'].length > 0) {
				obj['where']['tags.id'] = {
					type: 'in',
					value: this.filteredObj['tagList'],
				}
			}
			if (typeof this.sendFilteredReqObj != 'undefined' && this.sendFilteredReqObj['laneList'] != null && this.sendFilteredReqObj['laneList'].length > 0) {
				obj['where']['lane.id'] = {
					type: 'in',
					value: this.sendFilteredReqObj['laneList'],
				}
			}
			if (this.losStatus) {
				obj['where']['leadSource'] = {
					type: 'search',
					value: 'WEBSITE',
				}
			}
			if (this.searchText && this.searchText.trim() != null) {
				let name = {
					type: 'multi_match_query',
					value: [
						{
							field: 'displayName',
							value: this.searchText,
							type: 'like',
						},
						{
							field: 'displaySequenceName',
							value: this.searchText,
							type: 'like',
						},
						{
							field: 'referenceId',
							value: this.searchText,
							type: 'like',
						},
					],
				}

				obj['where']['multi_match_query'] = name
				obj['size'] = 20
			}
			this.isLoading = true
			// this.dataSource = new MatTableDataSource(this.leadListing);
			this.getLaneTask(obj, this.laneTaskList)
		}
	}

	getLaneTask(obj, lanetaskList?, isFromGlobalSearch?) {
		let name
		if (isFromGlobalSearch) {
			this.searchText = isFromGlobalSearch
		}
		if (this.searchText && this.searchText.trim() != '') {
			let name = {
				type: 'multi_match_query',
				value: [
					{
						field: 'displayName',
						value: this.searchText,
						type: 'like',
					},
					{
						field: 'displaySequenceName',
						value: this.searchText,
						type: 'like',
					},
					{
						field: 'referenceId',
						value: this.searchText,
						type: 'like',
					},
				],
			}

			obj['where']['multi_match_query'] = name
			obj['size'] = this.pageSize
		}

		this._kService.getLaneTask(obj).subscribe((res) => {
			this.isLoading = false
			this.pageIndex++
			if (lanetaskList) {
				this.laneTaskList = lanetaskList.concat(res['result'])
				this.dataSource.data = this.dataSource.data.concat(res['result'])
			} else {
				this.laneTaskList = res['result']
			}
			// this.dataSource.data = res['result'];
			if (this.laneTaskList.length != 0) {
				this.boardLane.map((val) => {
					if (isFromGlobalSearch) {
						val['taskList'] = []
					}
					if (lanetaskList) {
						if (val.laneDTO.id == this.laneTaskList[0].laneId) {
							val['taskList'] = this.laneTaskList
						}
					} else if (isFromGlobalSearch) {
						this.laneTaskList.map((data) => {
							if (val.laneDTO.id == data['laneId']) {
								val['taskList'].push(data)
							}
						})
					} else {
						if (val.laneDTO.id == this.laneTaskList[0].laneId) {
							val['taskList'] = this.laneTaskList
						} else {
							val['taskList'] = []
						}
					}
				})
			} else {
				this.boardLane.map((val) => {
					for (let i = 0; i < this.boardLane.length; i++) {
						if (val.laneDTO.id == this.boardLane[i]['laneDTO'].id) {
							val['taskList'] = []
						}
					}
				})
			}
			this.getLabelCount(res)
		})
	}

	getLabelCount(task?) {
		return task.totalRecords
	}
	isHorizontalScroll: boolean = false
	onScrollHorizontal(event, length) {
		console.log('length', length, 'boardlane', this.boardLane['totalRecords'])
		if (length < this.boardLane['totalRecords']) {
			this.isHorizontalScroll = true
			this.lanePageFrom = this.lanePageFrom + 1
			this.lanePageSize = this.lanePageSize
			this.fetchBoardLane()
		}
	}

	getTaskRemarks(task) {
		if (task['remark']) {
			task['remark'].map((val) => {
				if (val.laneId == task.laneId) {
					return val.remarks
				}
			})
		}
	}

	setReasonToMove: string
	selectedTaskArrayList = []
	assignLaneTaskOneToAnother(obj, event) {
		console.log('obj--', obj)
		console.log('board', this.boardLane)
		this.boardLane.map((val) => {
			if (val['laneDTO'].id == obj.laneId) {
				this.selectedTaskArrayList = val['taskList']
			}
		})
		let assignee = []
		this.selectedTaskArrayList.map((ele) => {
			if (ele.id == obj.id) {
				assignee = ele['assignee']
			}
		})
		obj['assignees'] = assignee
		obj['boardName'] = this.boardLane[0].laneDTO.board.name
		let reqObj = {
			id: obj.id,
		}

		this._kService.checkLaneMovementValidation(reqObj).subscribe((res) => {
			if (res['status'] == 200) {
				if (res['result']['missingFields'] != null && res['result']['missingFields'].length != 0) {
					this.toasterService.error('Required Fields are not present')
				} else {
					const dialogRef = this.dialog.open(LaneMoveComponent, {
						disableClose: true,
						width: '55%',
						height: '70%',
						data: {
							response: res['result'],
							obj: obj,
						},
					})
					dialogRef.afterClosed().subscribe((result) => {
						this.setReasonToMove = result
						if (result != false) {
							this.callForTaskMovement(event, obj)
						}
					})
				}
			}
		})
	}

	userProfileName: string
	setAssigneename(list) {
		if (list.user != null) {
			let userName = list.user.name
			var matches = userName.match(/\b(\w)/g)
			if (matches.length > 2) {
				matches.splice(2, matches.length)
			}
			this.userProfileName = matches.join('').toUpperCase()
			return this.userProfileName
		}
	}
	viewLaneTask(task) {
		const dialogRef = this.dialog.open(ViewLaneComponent, {
			disableClose: false,
			width: '70%',

			//  position : {bottom: '0px', right:'0px'} ,
			data: {
				laneTask: task,
			},
		})
	}
	changeAssignee(task) {
		const dialogRef = this.dialog.open(LaneTaskUsersTabComponent, {
			disableClose: false,
			panelClass: 'changeAssigneeWrap',
			//  position : {bottom: '0px', right:'0px'} ,
			data: {
				laneTask: task,
			},
		})
		dialogRef.afterClosed().subscribe((result) => {
			this.fetchBoardLane()
		})
	}
	showHistory(task) {
		const dialogRef = this.dialog.open(HistoryTabComponent, {
			disableClose: false,
			width: '70%',
			height: '80%',
			//  position : {bottom: '0px', right:'0px'} ,
			data: {
				laneTask: task,
			},
		})
	}

	openCardDetails(task) {
		if (this.boardType == 'DOMESTIC') {
			if (this.isPreQualifiedLane) {
				window.open('/#/pre-qualified/' + task)
			} else {
				this._kService.setLaneTaskId(task.id)
			}
			if (sessionStorage.domesticData) {
				let domesticObj = JSON.parse(sessionStorage.domesticData)
				domesticObj.isDomestic = true
				sessionStorage.setItem('domesticData', JSON.stringify(domesticObj))
			}
			if (sessionStorage.eximData) {
				let eximObj = JSON.parse(sessionStorage.eximData)
				eximObj.isExim = false
				sessionStorage.setItem('eximData', JSON.stringify(eximObj))
			}
			if (task.lane.name != 'approved-onboarding') {
				window.location.href = '/#/lead-details/' + `${task.lane.name}` + '/' + task.id
			} else {
				window.location.href = '/#/lead-details/application-documents-submitted/' + task.id
			}
		} else {
			if (sessionStorage.domesticData) {
				let domesticObj = JSON.parse(sessionStorage.domesticData)
				domesticObj.isDomestic = false
				sessionStorage.setItem('domesticData', JSON.stringify(domesticObj))
			}
			if (sessionStorage.eximData) {
				let eximObj = JSON.parse(sessionStorage.eximData)
				eximObj.isExim = true
				sessionStorage.setItem('eximData', JSON.stringify(eximObj))
			}
			if (!this.isPreQualifiedLane) {
				task['boardId'] = this.boardId
				this.boardLane.map((ele) => {
					if (ele.laneDTO.id == task.laneId) {
						task['isEditAccess'] = ele.laneDTO.isEditAccess
					}
				})
				// window.open('/#/cardDetails/' + task.id, "_blank");
				window.location.href = '/#/cardDetails/' + task.id
			} else {
				window.open('/#/pre-qualified/' + task)
			}
		}
	}
	showSuppliers(x){
	let businessType = x.domesticData['NEW_LEADS'] && x.domesticData['NEW_LEADS'].businessProduct ? x.domesticData['NEW_LEADS'].businessProduct : '';
	return businessType != 'BUSINESS_LOAN';
	}
	getPrimaryInfo(x, returnVal) {
		if (x) {
			let buyerSum = 0
			let supplierSum = 0
			let buyer = x.domesticData['NEW_LEADS'] && x.domesticData['NEW_LEADS'].buyer ? x.domesticData['NEW_LEADS'].buyer : []
			let supplier = x.domesticData['NEW_LEADS'] && x.domesticData['NEW_LEADS'].supplier ? x.domesticData['NEW_LEADS'].supplier : []
			let businessType = x.domesticData['NEW_LEADS'] && x.domesticData['NEW_LEADS'].businessProduct ? x.domesticData['NEW_LEADS'].businessProduct : ''
			if (businessType == 'SID') {
				this.businessName = 'Buyer'
				if (returnVal == 'length') {
					return buyer.length
				}
				if (returnVal == 'sum') {
					buyer.forEach((item) => {
						buyerSum = buyerSum + item.limitRequested
					})
					return buyerSum
				}
			} else if (businessType == 'PID' || businessType == 'PO') {
				this.businessName = 'Supplier'
				if (returnVal == 'length') {
					return supplier.length
				}
				if (returnVal == 'sum') {
					supplier.forEach((item) => {
						supplierSum = supplierSum + item.limitRequested
					})
					return supplierSum
				}
			}
			return 0
		}
		return 0
	}
	getPaginatorTableData(event) {
		this.size = event.pageSize ? event.pageSize : this.size
		if (!this.isSearchBox) {
			this.getPreQualifiedFilterObj()
		} else {
			this.getPreTable(this.searchValue, this.searchType)
		}
	}
	getCount(obj) {
		this.deleteObjects(obj)
		this.preSaleService.getFileLeadStats(obj).subscribe((res) => {
			this.statsCount = res['preQualifiedResult']
			for (let val in this.statsCount) {
				this.updateCountValue(val)
			}
		})
	}
	updateCountValue(val) {
		const indexToUpdate = this.subStatusList.findIndex((item) => item.key === val)
		if (indexToUpdate !== -1) {
			this.subStatusList[indexToUpdate].value = this.statsCount[val]
		}
	}
	deleteObjects(obj) {
		if (obj.where.status) {
			delete obj.where.status
		}
		if (delete obj.where.subStatus) {
			delete obj.where.subStatus
		}
		if (obj.from) {
			delete obj.from
		}
		if (obj.size) {
			delete obj.size
		}
	}
	// getStatsList(value){
	//   return Object.keys(value) + ' ' + `(${Object.values(value)})`
	// }
	// getCurrentStats(value){
	//   return (Object.keys(value)).toString();
	// }

	changeStatus(event, index?) {
		// toggle checkbox in statusList because apply button function gonna perform its function
		this.leadStatus.map((val) => {
			if (val) {
				this.leadStatus[index].checked = event.checked
			}
		})
	}

	resetFilterStatus() {
		this.leadStatus.map((item) => {
			item.checked = false
			return item
		})
		this.statusList = []
		this.applyStatusFilter()
	}
	applyStatusFilter() {
		this.statusList = []
		this.leadStatus.forEach((val) => {
			if (val.checked) {
				this.statusList.push(val.value)
			}
		})
		this.removeStatusClass()
		this.applyHeaderStatusFilter()
	}
	applyHeaderStatusFilter() {
		this.getPreQualifiedFilterObj()
	}
	resetFilters() {
		//for status
		this.leadStatus.map((item) => {
			item.checked = false
			return item
		})
		this.statusList = []
	}

	clearAllFilter() {
		this.clarAllPreQualifiedFilter()
		this.getPreQualifiedFilterObj()
		this.clearSearchBox()
		this.selectedBuyerName = null
		this.searchLeadName = null
		this.searchValue = null // for pagination purpose
		this.searchType = null // for pagination purpose
		this.isSearchBox = false;
		this.selectedFromDate = null;
		this.selectedToDate = null;
	}
	downloadLead() {
		this.preSaleService.downloadTemplate(this.businessType).subscribe((res) => {
			if (res) {
				this.toasterService.success('Template Download Successfully')
				var contentDispositionHeader = res.headers.get('content-disposition')
				let resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1]
				resultDownload = resultDownload.replace(/"/g, '')
				saveAs(res.body, resultDownload)
				return resultDownload.replace(/"/g, '')
			}
		})
	}
	getLeadFileConfig() {
		this.preSaleService.getLeadFileConfig().subscribe((res) => {
			this.leadConfigResult = res['result']['config']
		})
	}
	onFileChange(event) {
		const businessType = this.boardType == 'EXIM' ? 'Exim' : 'Domestic'
		this.leadFile = event.target.files[0]
		const uploadData = new FormData()
		uploadData.append('file', this.leadFile, this.leadFile.name)
		this.pagesService.getUploadMappingHeaders(uploadData).subscribe((res) => {
			if (res['status'] == 200) {
				const dialogRef = this.dialog.open(UploadMappingComponent, {
					disableClose: true,
					maxWidth: '100vw',
					width: '100vw',
					height: '100vh',
					data: {
						fileName: this.leadFile.name,
						pageName: 'preSale',
						data: res,
						uploadData: uploadData,
						config: this.leadConfigResult,
						businessType: businessType,
					},
				})
				dialogRef.afterClosed().subscribe((result) => {
					this._kService.refreshKboard.next(true)
				})
			}
		})
	}
	getScore(score) {
		if (score >= 7) {
			return 'greenScore'
		} else if (score >= 4) {
			return 'orangeScore'
		} else {
			return 'redScore'
		}
	}
	getUserListBasedOnRoles() {
		this._kService.getPreQualifiedUserList().subscribe((res) => {
			this.fetchUser = res['result']
			res['result'].map((ele) => {
				this.userList.push(ele.userName)
			})
			this.getCall()
		})
	}
	getCall() {
		this.filteredOptions = this.myControl.valueChanges.pipe(
			startWith(''),
			map((value) => this._filter(value || '')),
		)
	}
	private _filter(value: string): string[] {
		const filterValue = value.toLowerCase()
		return this.userList.filter((option) => option.toLowerCase().includes(filterValue))
	}

	selectedUserList(event: MatAutocompleteSelectedEvent, val) {
		let seluser = event.option.viewValue
		if (val == 'assignee') {
			this.fetchUser.map((ele) => {
				if (seluser == ele.userName) {
					this.assigneeId = ele.userId
				}
			})
		}
	}

	getSearchValueChange() {
		this.searchControl.valueChanges.pipe(
		  debounceTime(400), // Delay for 400ms after the user stops typing
		  distinctUntilChanged(), // Only emit when the value changes
		  takeUntil(this.destroy$) // Unsubscribe when the component is destroyed
		).subscribe((value) => {
		  this.searchLead(value); // Call search method with the value
		});
	  }

	searchLead(value) {
		if (value != null && value.trim() != '') {
			this.autoSuggestloading = true
			let obj = {
				indexName: 'credlix_presales',
				searchTerm: value,
				isSupplier: true,
			}
			this.preSaleService.getLeadIds(obj).subscribe((res: any) => {
				this.autoSuggestloading = false
				this.leadList = res['result']
			})
		} else {
			this.leadList = null
		}
	}
	changePreQualifiedAssigne(element) {
		let component = null
		component = LaneTaskUsersTabComponent
		const dialogRef = this.dialog.open(component, {
			disableClose: false,
			width: '30%',
			height: '100%',
			position: { bottom: '0px', right: '0px' },
			data: {
				laneTask: element,
				preQualified: true,
			},
		})
		dialogRef.afterClosed().subscribe((result) => {
			this.getPreQualifiedFilterObj()
		})
	}
	searchBuyers(value) {
		if (value != null && value.trim() != '') {
			this.buyerDataLoading = true
			let obj = {
				indexName: 'credlix_presales',
				searchTerm: value,
				isSupplier: false,
			}
			this.preSaleService.getBuyerList(obj).subscribe((res: any) => {
				this.buyerDataLoading = false
				this.buyerList = res['result']
			})
		} else {
			this.buyerList = null
		}
	}
	selectedBuyer(element) {
		this.selectedBuyerName = element.buyerName
		this.selectedBuyerId = element.id
		this.buyerList = null
		this.openCardDetails(element.id)
	}
	removeStatusClass() {
		this.isClickedProgress = false
		this.isClickedMove = false
		this.isClickedFresh = false
		this.isClickedDropped = false
		this.checkedCurrentStageList = []
	}
	onStatusChange(value) {
		this.statusList = []
		if (value == 'FRESH_LEAD') {
			this.isClickedFresh == false ? this.statusList.push(value) : this.statusList.pop(value)
			this.isClickedFresh = !this.isClickedFresh
			this.isClickedProgress = false
			this.isClickedMove = false
			this.isClickedDropped = false
			this.checkedCurrentStageList = []
		} else if (value == 'IN_PROGRESS') {
			this.isClickedProgress == false ? this.statusList.push(value) : this.statusList.pop(value)
			this.isClickedProgress = !this.isClickedProgress
			this.isClickedFresh = false
			this.isClickedMove = false
			this.isClickedDropped = false
			this.checkedCurrentStageList = []
		} else if (value == 'MOVE_TO_LOS') {
			this.isClickedMove == false ? this.statusList.push(value) : this.statusList.pop(value)
			this.isClickedMove = !this.isClickedMove
			this.isClickedFresh = false
			this.isClickedProgress = false
			this.isClickedDropped = false
			this.checkedCurrentStageList = []
		} else if (value == 'DROPPED') {
			this.isClickedDropped == false ? this.statusList.push(value) : this.statusList.pop(value)
			this.isClickedDropped = !this.isClickedDropped
			this.isClickedFresh = false
			this.isClickedProgress = false
			this.isClickedMove = false
			this.checkedCurrentStageList = []
		}
		this.leadStatus = [
			{ name: 'Fresh Lead', value: 'FRESH_LEAD', checked: false },
			{ name: 'In Progress', value: 'IN_PROGRESS', checked: false },
			{ name: 'Move to LOS', value: 'MOVE_TO_LOS', checked: false },
			{ name: 'Dropped', value: 'DROPPED', checked: false },
		] //remove checked status from Header Lead Status Filter
		if (!this.isSearchBox) {
			this.getPreQualifiedFilterObj()
		} else {
			this.getPreTable(this.searchValue, this.searchType)
		}
	}
	laneChangeOnCurrentStatus() {
		this.pagesService.currentStatusLos(true)
	}
	getPreTable(val, searchType) {
		this.searchValue = val
		if (searchType) {
			this.searchType = searchType
		}
		this.isSearchBox = true
		// this.clarAllPreQualifiedFilter();    // to remove all filters
		let obj = {
			where: {
				isDeleted: {
					type: 'search',
					value: false,
				},
			},
			sort: {
				field: 'updatedAt',
				order: 'desc',
			},
			indexName: 'credlix_presales',
			searchTerm: this.searchValue,
			isSupplier: this.searchType == 'leadSearch' ? true : false,
		}
		this.getCount(obj) // delete status object in this function so that's why write on top from status obj which is written below
		if (this.statusList.length > 0) {
			obj['where']['status'] = {
				type: 'search_in',
				value: this.statusList,
			}
		}

		obj['from'] = this.paginator && this.paginator['pageIndex'] ? this.paginator['pageIndex'] : 0
		obj['size'] = this.size
		this.preSaleService.getLeadBySearch(obj).subscribe((res) => {
			this.isLoading = false
			this.preQualifiedData = res['result']
			this.dataSource1 = new MatTableDataSource(this.preQualifiedData)
			this.dataSource1.data = res['result']
			this.totalRecords = res['total']
			this.clearSearchBox()
		})
	}
	clearSearchBox() {
		this.leadList = null // close search Search Box
		this.buyerList = null // close Buyer Search Box
	}
	onCurrentStageChange(value) {
		this.checkedCurrentStageList = value
		this.getPreQualifiedFilterObj()
	}
	/** Whether the number of selected elements matches the total number of rows. */
	isAllPreSelected() {
		const numSelected = this.selection.selected.length
		const numRows = this.dataSource1.data.length
		return numSelected === numRows
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterPreToggle() {
		this.isAllPreSelected() ? this.selection.clear() : this.dataSource1.data.forEach((row) => this.selection.select(row))
	}

	/** The label for the checkbox on the passed row */
	checkboxLabel(row?): string {
		if (!row) {
			return `${this.isAllPreSelected() ? 'deselect' : 'select'} all`
		}
		return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`
	}
	changeBulkAction(value) {
		let ids = []
		for (let i = 0; i < this.selection.selected.length; i++) {
			if (this.selection.selected[i].status !== 'MOVE_TO_LOS') {
				ids.push(this.selection.selected[i].id)
			}
		}
		if (this.selection.selected.length > 0) {
			if (value == 'bulkAssignee') {
				const assigneDialog = this.dialog.open(LaneTaskUsersTabComponent, {
					disableClose: true,
					width: '30%',
					height: '100%',
					position: { bottom: '0px', right: '0px' },
					data: {
						preQualified: true,
						isBulk: true,
					},
				})
				assigneDialog.afterClosed().subscribe((result) => {
					if (result.userId) {
						let assigneeId = result.userId
						this.changeBulkAssignee(ids, assigneeId)
						this.selection = new SelectionModel(true, [])
						this.getPreQualifiedFilterObj()
					} else {
						this.preQualifiedBulk = null
					}
				})
			}
		} else {
			this.toasterService.error('Please Select Leads')
			this.preQualifiedBulk = null
		}
	}

	changeBulkAssignee(ids, userId) {
		let obj = {
			leadIds: ids,
			userId: userId,
		}
		this.preSaleService.changeBulkAssignee(obj).subscribe((res) => {
			this.toasterService.success('Assignee Change in Bulk Successfully')
			this.preQualifiedBulk = null
		})
	}
	getCampaignList() {
		let obj = {
			where: {
				isDeleted: {
					type: 'search',
					value: false,
				},
				businessType: {
					type: 'search_in',
					value: this.boardType == 'EXIM' ? ['Exim', 'Export'] : ['Domestic'],
				},
			},
			sort: {
				field: 'updatedAt',
				order: 'desc',
			},
			indexName: 'credlix_presales',
		}
		this.preSaleService.getCampaignList(obj).subscribe((res) => {
			this.campaignList = res['result']
		})
	}

	getLeadSourceList(){
		let obj = {
			where: {
				isDeleted: {
					type: 'search',
					value: false,
				},
				businessType: {
					type: 'search_in',
					value: this.boardType == 'EXIM' ? ['Exim', 'Export'] : ['Domestic'],
				},
			},
			sort: {
				field: 'updatedAt',
				order: 'desc',
			},
			indexName: 'credlix_presales',
		}
		this.preSaleService.getLeadSourceList(obj).subscribe((res) => {
			this.sourceList = res['result'].filter((ele) => ele != '');
		});
	}
	leadScoreOrderBy() {
		this.isLeadOrderBy = !this.isLeadOrderBy
		if (this.isLeadOrderBy) {
			this.leadOrderBy = 'asc'
		} else {
			this.leadOrderBy = 'desc'
		}
		this.getPreQualifiedFilterObj()
	}
	getHighlightedFilter(key) {
		if (key == true) {
			return 'active'
		}
	}
	leadSourceDialog() {
		let obj = {
			leadSource: this.selectedLeadSource,
		}
		const dialogRef = this.dialog.open(LosFiltersComponent, {
			width: '373px',
			height: '100%',
			data: obj,
			panelClass: 'eximSetting',
		})
		dialogRef.afterClosed().subscribe((result) => {
			this.selectedLeadSource = result
			if (this.selectedLeadSource && this.boardId) {
				this._kService.isTabChanageFromTile = true
				this.updatebaordList(this.selectedLeadSource, this.boardId)
			} else {
				this.fetchBoardLane()
			}
		})
	}

	isNumber(val) {
		try {
			return typeof val === 'number' && !isNaN(val)
		} catch (error) {
			console.error(error)
			return false
		}
	}

	ngOnDestroy() {
		// Cleanup to prevent memory leaks
		this.destroy$.next();
		this.destroy$.complete();
	}
}
